exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-easter-js": () => import("./../../../src/pages/easter.js" /* webpackChunkName: "component---src-pages-easter-js" */),
  "component---src-pages-et-3-js": () => import("./../../../src/pages/et3.js" /* webpackChunkName: "component---src-pages-et-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifest-js": () => import("./../../../src/pages/manifest.js" /* webpackChunkName: "component---src-pages-manifest-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-voucher-js": () => import("./../../../src/pages/voucher.js" /* webpackChunkName: "component---src-pages-voucher-js" */),
  "component---src-pages-warsztaty-js": () => import("./../../../src/pages/warsztaty.js" /* webpackChunkName: "component---src-pages-warsztaty-js" */),
  "component---src-pages-wydarzenia-js": () => import("./../../../src/pages/wydarzenia.js" /* webpackChunkName: "component---src-pages-wydarzenia-js" */)
}

